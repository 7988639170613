html {
  display: grid;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #434A51;
  overscroll-behavior: none;
  transition: background-color 1s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

pre {
  font-size: 14px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

textarea {
  font-family: inherit;
  font-size: inherit;
}

p {
  margin-bottom: 10px;
}

h1 {
  font-size: 40px;
  font-weight: 300;
  margin: 5px;
}

h1, h2, h3, h4, h5, h6 {
  color: #FCFCFC;
  margin: 0;
}

h6 {
  font-weight: 500;
  margin: 0;
}

.btn {
  -webkit-touch-callout:none;
}
